import { useState } from 'react';
import './add-island-member.styles.css';
import { ToastContainer, toast } from 'react-toastify';

function AddIslandMemberDialog(props: any) {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [telephone, setTelephone] = useState<string>('');
    const [role, setRole] = useState<string>('Titkár');

    /**
     * Closes the dialog.
     * @param _event the click event
     */
    function closeDialog(_event: any): void {
        props.onClose(null);
    }

    /**
     * Checks the member properties.
     * If everything is ok, sends the payload in the close.
     */
    function addMember(): void {
        if (!name || !email || !telephone || !role) {
            toast.error('Nincs kitöltve minden mező!');
            return;
        }
        props.onClose({ name, email: email.toLowerCase(), telephone, role });
    }

    return (<div id="add-island-member-dialog">
        <div id="add-island-member-dialog-content" className='fade-in-up light-shadow'>
            <h3 id='add-island-member-title'>Tag hozzáadása</h3>
            <div id="add-island-member-form">
                <input onKeyUp={(_event: any) => setName(_event.target.value)} className='add-island-member-form-input' placeholder='Teljes név' />
                <input onKeyUp={(_event: any) => setEmail(_event.target.value)} className='add-island-member-form-input' placeholder='E-mail cím' />
                <input onKeyUp={(_event: any) => setTelephone(_event.target.value)} className='add-island-member-form-input' placeholder='Telefonszám' />
                <select onChange={(_event: any) => setRole(_event.target.value)} style={{ textAlign: 'left' }}>
                    <option value='Titkár'>Titkár</option>
                    <option value='Moderátor'>Moderátor</option>
                </select>
                <button onClick={addMember} className='primary-button'>Hozzáad</button>
                <button onClick={closeDialog}>Bezárás</button>
            </div>
        </div>
    </div>);
}

export default AddIslandMemberDialog;