
/**
 * Fetches the user list from the API server with
 * the given filters in the payload.
 * @param payload the payload to send
 * @returns on success it returns the users list
 */
async function list(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = sessionStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/list?page=${payload.page}&count=${payload.count}&activated=${payload.activated}&keyword=${payload.keyword}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    const errorResponse = await response.json();
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data});
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Activates the pending user registration's user.
 * @param payload the payload to send
 * @returns OK if the action was successful
 */
async function activate(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = sessionStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/activate`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    const errorResponse = await response.json();
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data});
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

async function decline(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = sessionStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/decline`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    const errorResponse = await response.json();
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data});
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

export default {
    list,
    activate,
    decline
};