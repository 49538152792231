import './invitation.styles.css';
import TiszaLogo from '../../assets/tiszalogo.png';
import { useEffect, useState } from 'react';
import Loader from '../../components/loader/loader.component';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import IslandService from '../../services/island.service';

function InvitationPage() {

    const { code } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [status, setStatus] = useState<number>(0);
    const [islandData, setIslandData] = useState<any>(null);

    useEffect(() => {

        // Sending the activation request to the API server.
        if (code) {
            IslandService.memberJoin(code)
                .then((response: any) => {
                    setStatus(200);
                    setIslandData(response.data);
                })
                .catch((err: any) => {
                    setStatus(err.code);
                });
        }

        setTimeout(() => {
            setLoading(false);
        }, 500);

    }, []);

    return (
        <div id='invitation-window'>
            <div id='invitation-window-overlay'>

                <img id='invitation-logo' src={TiszaLogo} />

                {loading && <div className='subpage-loader'>
                    <Loader />
                </div>}

                {!loading && <div className='invitation-status-message fade-in-up'>
                    {status == 404 && <div>
                        <h2>Valami hiba történt!</h2>
                        <p>Invitáció érvénytelen!</p>
                        <p>Kérdés esetében írj a support@tiszaszigetek.online e-mail címre.</p>
                    </div>}

                    {status == 200 && <div>
                        <h2>Sikeres aktiváció!</h2>
                        <p>Sikeresen csatlakoztál a(z) {islandData.name} szigethez!</p>
                        <p>További információkért keresd az alapítót!<br></br>({islandData.ownerName} - {islandData.ownerEmail})</p>
                    </div>}

                    {status == 409 && <div>
                        <h2>Valami hiba történt!</h2>
                        <p>A megadott e-mail címmel már elérted a szigetekhez való csatlakozási maximumot.</p>
                        <p>Kérdés esetében írj a support@tiszaszigetek.online e-mail címre.</p>
                    </div>}
                </div>}
            </div>
            <ToastContainer />
        </div>
    );

}

export default InvitationPage;