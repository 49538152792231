import { useEffect, useState } from 'react';
import './island.styles.css';
import { ToastContainer, toast } from 'react-toastify';

import IslandService from '../../../../services/island.service';
import { authProvider } from '../../../../auth.provider';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../components/loader/loader.component';
import IslandMembersComponent from './components/members/island-members.component';
import IslandDetailsComponent from './components/details/island-details.component';
import AddIslandMemberDialog from '../../../../components/add-island-member/add-island-member.dialog';

function IslandPage() {

    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [island, setIsland] = useState<any>(null);
    const [subPage, setSubpage] = useState<string>('members');

    const [showMemberAddPanel, setShowMemberAddPanel] = useState<boolean>(false);

    useEffect(() => {
        getIslandData();
    }, []);

    /**
     * Fetches the island data from the server.
     */
    function getIslandData() {
        setLoading(true);

        if (id) {
            IslandService.get(id)
                .then((response: any) => {
                    setIsland(response.data);
                    setLoading(false);
                })
                .catch((err) => {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                        return;
                    }

                    if (err.error) {
                        toast.error(err.error);
                        return;
                    }

                    if (err.status) {
                        toast.error(err.status);
                        return;
                    }
                });
        } else {
            toast.error('Nem lett megadva a sziget azonosítója!');
        }
    }

    function onMemberAddPanelClosed(member: any): void {
        setShowMemberAddPanel(false);
        if (member) {
            // Invite the member to the island
            IslandService.invite({ islandId: id, email: member.email, name: member.name, telephone: member.telephone, role: member.role })
                .then(() => {
                    toast.info('Meghívó elküldve!');
                })
                .catch((err) => {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                        return;
                    }

                    if (err.error) {
                        toast.error(err.error);
                        return;
                    }

                    if (err.status) {
                        toast.error(err.status);
                        return;
                    }
                });
        }
    }

    /**
     * Called when the child data component gots
     * a save request which could be change the island data.
     */
    function islandDataChanged(): void {
        getIslandData();
    }

    return <div className='subpage-window'>
        <ToastContainer />
        {showMemberAddPanel && <AddIslandMemberDialog onClose={onMemberAddPanelClosed} />}

        {loading && <div className='subpage-loader'>
            <Loader />
        </div>}

        {!loading && island && <div>
            <div id="island-overview-header">
                <img id='island-overview-island-logo' src={island.logo} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <h2 className="subpage-title">{island.name}</h2>

                    {island.status === 'waiting-for-approve' && <div className='island-overview-status-panel'>
                        <div className='island-overview-status-circle'></div>
                        <div>Jóváhagyásra vár</div>
                    </div>}

                    {island.status === 'pending' && <div className='island-overview-status-panel'>
                        <div className='island-overview-status-circle'></div>
                        <div>Bejegyzés alatt</div>
                    </div>}

                    {island.status === 'completed' && <div className='island-overview-status-panel'>
                        <div className='island-overview-status-circle island-overview-status-circle-green'></div>
                        <div>Bejegyzett</div>
                    </div>}
                </div>
            </div>

            <div id='island-overview-menu'>
                <button onClick={() => setSubpage('members')} className='island-overview-menu-button'>Tagok</button>
                <button onClick={() => setSubpage('details')} className='island-overview-menu-button'>Adatok</button>
                <button onClick={() => setShowMemberAddPanel(true)} className='primary-button' style={{ width: 140 }}>Tag meghívása</button>
            </div>

            {subPage == 'members' && <IslandMembersComponent id={island._id} />}

            {subPage == 'details' && <IslandDetailsComponent onDataChanged={islandDataChanged} id={island._id} />}

        </div>}


    </div>
};

export default IslandPage;