import type { LoaderFunctionArgs } from "react-router-dom";

import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect
} from "react-router-dom";
import { authProvider } from "./auth.provider";
import HomePage from "./pages/home/home.page";
import LoginPage from "./pages/login/login.page";
import IslandPage from "./pages/home/subpages/island/island.page";
import ProfilePage from "./pages/home/subpages/profile/profile.page";
import RegistrationPage from "./pages/registration/registration.page";
import UsersPage from "./pages/home/subpages/users/users.page";
import ServerPage from "./pages/home/subpages/server/server.page";
import MyIslandsPage from "./pages/home/subpages/myislands/myislands.page";
import IslandCreatePage from "./pages/home/subpages/island-create/island-create.page";
import InvitationPage from "./pages/invitation/invitation.page";
import AllIslandsPage from "./pages/home/subpages/all-islands/all-islands.page";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader({ request }) {

      const url = new URL(request.url);
      const pathname = url.pathname;

      // Redirect to /home if path is / and not /login or /first-steps
      if (pathname === '/' || pathname === '/web/' || pathname === '/web') {
        return redirect('/login');
      }

      return null
    },
    Component: MainRouter,
    children: [
      {
        path: "home",
        loader: protectedLoader,
        Component: HomePage,
        children: [
          {
            path: "myislands",
            loader: protectedLoader,
            Component: MyIslandsPage
          },
          {
            path: "island-create",
            loader: protectedLoader,
            Component: IslandCreatePage
          },
          {
            path: "island/:id",
            loader: protectedLoader,
            Component: IslandPage
          },
          {
            path: "users",
            loader: protectedLoader,
            Component: UsersPage
          },
          {
            path: "profile",
            loader: protectedLoader,
            Component: ProfilePage
          },
          {
            path: "all-islands",
            loader: protectedLoader,
            Component: AllIslandsPage
          },
          {
            path: 'server',
            loader: protectedLoader,
            Component: ServerPage
          }
        ]
      },
      {
        path: "login",
        loader: loginLoader,
        Component: LoginPage,
      },
      {
        path: "registration",
        Component: RegistrationPage,
        loader: loginLoader
      },
      {
        path: "invitation/:code",
        Component: InvitationPage,
        loader: loginLoader
      }
    ]
  },
  {
    path: "/logout",
    async action() {
      // We signout in a "resource route" that we can hit from a fetcher.Form
      await authProvider.signout();
      return redirect("/");
    },
  },
], { basename: '/web' });

export default function App() {
  return (
    <RouterProvider router={router} />
  );
}

function MainRouter() {
  return (
    <Outlet />
  );
}

async function loginLoader({ request }: LoaderFunctionArgs) {
  if (authProvider.isAuthenticated()) {
    return redirect('/home/island');
  } else {
    return null;
  }
}

async function protectedLoader({ request }: LoaderFunctionArgs) {
  if (!authProvider.isAuthenticated()) {
    return redirect('/login');
  } else {
    if (new URL(request.url).pathname.endsWith('/home')) {
      return redirect('/home/island');
    }
  }
  return null;
}