import { useEffect, useRef, useState } from 'react';
import './users.styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UsersService from '../../../../services/users.service';
import Loader from '../../../../components/loader/loader.component';
import ArrowLeftIcon from '../../../../icons/arrow-left.icon';
import ArrowRightIcon from '../../../../icons/arrow-right.icon';
import { useNavigate } from 'react-router-dom';
import { authProvider } from '../../../../auth.provider';

function UsersPage() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<any[]>([]);
    const [activated, setActivated] = useState<boolean>(true);
    const [searchEmailKeyword, setSearchEmailKeyword] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const previousValues = useRef({ searchEmailKeyword, activated });
    const count = 50;

    useEffect(() => {
        fetchUsers();
    }, [activated, page, searchEmailKeyword]);

    /**
     * Fetches the users from the API server
     * with the actual configuration.
     */
    function fetchUsers(): void {
        setLoading(true);

        // If the keyword or the activated modified reset the page
        if (previousValues.current.searchEmailKeyword != searchEmailKeyword ||
            previousValues.current.activated != activated) {
            setPage(1);
        }

        // Getting the users list
        UsersService.list({ page, count, activated, keyword: searchEmailKeyword })
            .then((response) => {
                setUsers(response.data);
                setPageCount(response.headers.pageCount);
                setLoading(false);
            })
            .catch(err => {
                if (err.status) {
                    toast.error(err.status)
                } else {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                    }
                }
            });

        // Update previous values after checks
        previousValues.current = { searchEmailKeyword, activated };
    }

    /**
     * Modifies the activated value to modify
     * the users view.
     * @param data the activated view value
     */
    function modifyUsersView(data: any) {
        const activated = data === 'true';
        setActivated(activated);
    }

    /**
     * Handles the pagination by adding the index to the page.
     * @param index the index to add
     */
    function paginateTable(index: number): void {
        if (page + index < 1 || page + index > pageCount) {
            return;
        }
        setPage(page + index);
    }

    /**
     * Activate the given user registration.
     * @param email the email of the user
     */
    function activateUser(email: string): void {
        UsersService.activate({ email })
            .then((_) => {
                fetchUsers();
            })
            .catch(err => {
                if (err.status) {
                    toast.error(err.status)
                } else {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                    }
                }
            });
    }

    /**
     * Declines the user registrationr request.
     * Deletes the user data from the database.
     * @param email the email of the user
     */
    function declineUser(email: string): void {
        UsersService.decline({ email })
        .then((_) => {
            fetchUsers();
        })
        .catch(err => {
            if (err.status) {
                toast.error(err.status)
            } else {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                }
            }
        });
    }

    return <div className='subpage-window'>

        <h2 className="subpage-title">Felhasználók</h2>

        <div id='users-controls'>
            <select className='users-controls-select' value={activated.toString()} onChange={(_event: any) => modifyUsersView(_event.target.value)}>
                <option value='true'>Felhasználók</option>
                <option value='false'>Jelentkezések</option>
            </select>
            <input onKeyUp={(_event: any) => setSearchEmailKeyword(_event.target.value)} id='users-search-input' placeholder='Keresés e-mail cím alapján' />
            <div id='users-pagination'>
                <ArrowLeftIcon onClick={() => paginateTable(-1)} className='pagination-arrow-button' />
                {page} / {pageCount}
                <ArrowRightIcon onClick={() => paginateTable(1)} className='pagination-arrow-button' />
            </div>
        </div>

        {loading && <div className='subpage-loader'>
            <Loader />
        </div>}

        {!loading && users.length < 1 && <p className='users-no-data fade-in-up'>Nincs megjeleníthető eredmény!</p>}

        {!loading && users.length > 0 && <div className='fade-in-up'>
            {activated &&
                <table className='light-shadow user-list-table'>
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>E-mail</th>
                            <th>Telefonszám</th>
                            <th>Státusz</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user: any) => (
                            <tr key={user.email}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.telephone}</td>
                                <td>
                                    {user.enabled && <div className='user-status'><div className='user-status-circle user-status-circle-active'></div>Aktív</div>}
                                    {!user.enabled && <div className='user-status'><div className='user-status-circle user-status-circle-blocked'></div>Tiltott</div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}

            {!activated &&
                <table className='light-shadow user-list-table'>
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>E-mail</th>
                            <th>Telefonszám</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user: any) => (
                            <tr key={user.email}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.telephone}</td>
                                <td>
                                    <div className='user-registration-action-panel'>
                                        <div onClick={() => activateUser(user.email)} className='user-registration-action-button'>Elfogad</div>
                                        <div onClick={() => declineUser(user.email)} className='user-registration-action-button user-registration-action-button-decline'>Elutasít</div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
        </div>}
        <ToastContainer />
    </div>
}

export default UsersPage;